
import DatePicker from "@/components/utils/DatePicker.vue";
import ImageSelector from "@/components/utils/PhotoSelect.vue";
import DealerSelect from "@/components/dealer/DealerSelect.vue";
import {AddCoffeeFarmer, FarmerTypes,} from "@/store/modules/farmers/farmers.types";
import {DefaultState, GpsCoordinate} from "@/types/types";
import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {read, utils, WorkBook, WorkSheet} from "xlsx";
import {getVillageFromSubCounty} from "akwap";

const farmerX = namespace("Farmers");

@Component({
  components: {ImageSelector, DatePicker, DealerSelect},
})
export default class UploadCoffeeFarmers extends Vue {
  @farmerX.State("upload")
  public uploadInputsState!: DefaultState;

  @farmerX.Mutation(FarmerTypes.SET_UPLOAD_COFFEE_FARMER_DIALOG)
  public setUploadCoffeeFarmer!: (uploadFarmers: boolean) => void;

  @farmerX.Action(FarmerTypes.UPLOAD_COFFEE_FARMERS)
  public uploadCoffeeFarmers!: (inputs: AddCoffeeFarmer[]) => Promise<void>;

  public farmersAgent?: number = -1;
  public uploadFile?: File;
  public sheets: string[] = [];
  public uploadSheets: number[] = [];

  public uploadExcelStep = 1;
  public processedFile = false;

  public rules = [
    (value?: File): boolean | string =>
        !value || value.size < 2000000 || "File size should be less than 2 MB!",
  ];

  closeUploadInputDialog(): void {
    this.setUploadCoffeeFarmer(false);
  }

  processFile(): void {
    const reqs: AddCoffeeFarmer[] = [];
    const errors: RowError[] = [];
    for (const sheet of this.uploadSheets) {
      const data = this.loadData(this.sheets[sheet]);
      reqs.push(...data.data);
      errors.push(...data.errors);
    }

    this.rowErrors.splice(0, this.rowErrors.length);
    this.rowErrors.push(...errors);

    this.rowData.splice(0, this.rowData.length);
    this.rowData.push(...reqs);
    this.processedFile = true;
  }

  switchFile(): void {
    this.processedFile = false;
    this.uploadExcelStep = 1;
  }

  async saveRecordsToDb(): Promise<void> {
    if (this.rowData.length > 0) await this.uploadCoffeeFarmers(this.rowData);
  }

  loadSheets(file: File): void {
    this.uploadFile = file;
    this.hasFile = true;
    this.sheets.splice(0, this.sheets.length);
    this.uploadSheets.splice(0, this.uploadSheets.length);

    if (this.uploadFile) {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result) {
          this.workbook = read(reader.result);
          if (this.workbook) {
            this.sheets.push(...this.workbook.SheetNames);
          }
        }
      };

      reader.readAsArrayBuffer(this.uploadFile);
    }
  }

  loadData(sheet: string): SheetData {
    const reqs: AddCoffeeFarmer[] = [];
    const errors: RowError[] = [];
    if (this.workbook) {
      const workSheet: WorkSheet = this.workbook.Sheets[sheet];
      const data: DocRow[] = utils.sheet_to_json(workSheet);

      for (const row in data) {
        if (data[row].__rowNum__ > 6) {
          const result = this.validateRow(
              sheet,
              row as unknown as number,
              data[row]
          );

          if ((result as RowError).error) {
            errors.push(result as RowError);
          } else {
            reqs.push(result as AddCoffeeFarmer);
          }
        }
      }
    }

    return {
      data: reqs,
      errors: errors,
    };
  }

  validateRow(
      sheet: string,
      row: number,
      data: DocRow
  ): AddCoffeeFarmer | RowError {
    const errorRows: string[] = [];

    if (!data.__EMPTY_1) errorRows.push("SUB_COUNTY");
    if (!data.__EMPTY_2) errorRows.push("PARISH");
    if (!data.__EMPTY_3) errorRows.push("VILLAGE");
    if (!data.__EMPTY_4) errorRows.push("FARMER CODE");
    if (!data.__EMPTY_5) errorRows.push("FULL NAME");
    if (!data.__EMPTY_6) errorRows.push("GENDER");
    if (!data["Estimated Land Area in Acres"])
      errorRows.push("Total Land Acreage");

    const villages = getVillageFromSubCounty(
        {
          sub_county: data.__EMPTY_1?.trim() ?? "12345",
          parish: data.__EMPTY_2?.trim() ?? "12345",
          village: data.__EMPTY_3?.trim() ?? "12345",
        },
        0.8
    );
    if (villages.length == 0) {
      errorRows.push("VILLAGE");
    }

    if (errorRows.length > 0) {
      return {
        sheet: sheet,
        row: row,
        error: `missing field${
            errorRows.length > 1 ? "s" : ""
        } ${errorRows.join(",")}`,
      };
    }

    return {
      farmer_code: data.__EMPTY_4 || "",
      date_of_registration: new Date(data.__EMPTY_7 || ""),
      date_of_first_inspection: new Date(data.__EMPTY_8 || ""),
      date_of_last_inspection: new Date(data.__EMPTY_9 || ""),
      is_organic_compliant: data.__EMPTY_10 === "C",
      altitude: data.__EMPTY_12 || 0,
      gps_coordinates: ((): GpsCoordinate | undefined => {
        if (data.__EMPTY_11) {
          const cs = data.__EMPTY_11.split(",");

          return {
            lat: Number(cs[0]),
            lon: Number(cs[1]),
          };
        }

        return undefined;
      })(),
      acreage_under_construction: data.__EMPTY_13,
      acreage_other_crops: data.__EMPTY_14,
      number_coffee_plots: data.__EMPTY_15 || 0,
      acreage_for_coffee: data.__EMPTY_16 || 0,
      total_land_acreage: data["Estimated Land Area in Acres"] || 0,
      total_coffee_trees: data.__EMPTY_17 || 0,
      total_productive_coffee_trees: data.__EMPTY_18 || 0,
      parch_per_tree_per_year: data["Production Estimates"] || 0,
      total_yield: data.__EMPTY_19 || 0,
      village: villages[0].id,
      full_name: data.__EMPTY_5 || "",
      gender: data.__EMPTY_6 || "",
      agent: this.farmersAgent == -1 ? undefined : this.farmersAgent,
    };
  }

  public hasFile = false;
  public workbook?: WorkBook;
  public rowErrors: RowError[] = [];
  public rowData: AddCoffeeFarmer[] = [];
}

// types
type RowError = {
  sheet: string;
  row: number;
  error: string;
};

type DocRow = {
  __EMPTY_1?: string;
  __EMPTY_2?: string;
  __EMPTY_3?: string;
  __EMPTY_4?: string;
  __EMPTY_5?: string;
  __EMPTY_6?: string;
  __EMPTY_7?: string;
  __EMPTY_8?: string;
  __EMPTY_9?: string;
  __EMPTY_10?: string;
  "Estimated Land Area in Acres"?: number;
  "Production Estimates"?: number;
  __EMPTY_11?: string;
  __EMPTY_12?: number;
  __EMPTY_13?: number;
  __EMPTY_14?: number;
  __EMPTY_15?: number;
  __EMPTY_16?: number;
  __EMPTY_17?: number;
  __EMPTY_18?: number;
  __EMPTY_19?: number;
  __rowNum__: number;
};

type SheetData = {
  data: AddCoffeeFarmer[];
  errors: RowError[];
};
