
import {
  DealerTypes,
  DealerUserProfile,
} from "@/store/modules/dealer/dealer.types";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const dealerX = namespace("Dealer");

@Component({})
export default class DealerSelect extends Vue {
  @Prop() public label?: string;
  @Prop() public filled?: boolean;
  @Prop() public outlined?: boolean;
  @Prop() public solo?: boolean;
  @Prop() public dense?: boolean;
  @Prop() public hideDetails?: boolean;
  @Prop() public rules?: unknown[];
  @Prop() value?: number;

  @dealerX.Action(DealerTypes.LOAD_DEALERS)
  public loadDealers!: () => void;

  @dealerX.State("dealers")
  public dealers!: DealerUserProfile[];

  public loadingDealers = false;

  @Emit("input")
  save(id: string): string {
    return id;
  }

  mounted(): void {
    this.loadDealers();
  }
}
